export const environment = {
  production: false,
  encryptedKey: 'eyJ0eXiOiJDb3QiLCJhbGciOiJIUzI1NiJ9eyJ',
  version: '1.1.1',
  baseUrl: 'https://card-widget-dev.calopad.com/',
  hostName: 'https://card-api-dev.calopad.com',
  restAPI: '/api/',
  preferredCountries: ['ch', 'de', 'fr', 'gb', 'it', 'nl', 'fi'],
  deployUrl: 'https://card-widget-dev.calopad.com/'
}
