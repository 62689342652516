<div class="cp-cards-widget">
	<div class="stepper">
		<div class="container">
			<div class="stepper-inner">
				<div class="lang-box">
					<ng-select class="custom-select lang-select" [(ngModel)]="_currentLanguage" (change)="changeLanguage()"
						[searchable]="false" [clearable]="false">
						<ng-option value="en_US">
							<img src="{{deployUrl}}assets/images/flags/en.svg" height="25" width="30" />
							<span class="ml-2">EN</span>
						</ng-option>
						<ng-option value="de_CH">
							<img src="{{deployUrl}}assets/images/flags/de.svg" height="25" width="30" />
							<span class="ml-2">DE</span>
						</ng-option>
					</ng-select>
				</div>
	
				<mat-stepper labelPosition="bottom" #stepper linear (selectionChange)="selectedIndex = $event.selectedIndex" animationDuration="1000">
					<ng-template matStepperIcon="edit">
						<mat-icon class="stepper-icon">check_circle</mat-icon>
					</ng-template>
					<ng-template matStepperIcon="number" let-index="index">
						<mat-icon *ngIf="selectedIndex === 2" class="stepper-icon">check_circle</mat-icon>
						<span *ngIf="selectedIndex !== 2">{{ index+1 }}</span>
					</ng-template>
					<mat-step>
						<div class="stepper-contain">
							<h5 class="title-txt">{{ 'landingPage.enterCalopadCardCode' | translate }}</h5>
							<div class="form-fields">
								<div class="field-row">
									<div class="field-box full">
										<div class="code">
											<input [ngClass]="{ 'success': isCodeValid, 'field-error': (cardCode.touched && cardCode.invalid), 'field-error error': isCodeStatusValid }"
												placeholder="{{ 'landingPage.cardCode' | translate }}" type="text" [formControl]="cardCode"
												(blur)="verifyCardCode()">
											<mat-icon *ngIf="isCodeValid" class="success-icon">check_circle</mat-icon>
											<img *ngIf="isCodeStatusValid" src="{{deployUrl}}assets/images/cancel.svg" class="error-icon" alt="error icon">
										</div>
										<ng-container *ngTemplateOutlet="requiredError; context: { control: cardCode }"></ng-container>
										<ng-container *ngIf="isCodeStatusValid">
											<p class="error-message">{{ 'toasterMessage.cardCodeValid' | translate }}</p>
										</ng-container>
									</div>
								</div>
							</div>
							<div class="btn-box">
								<button class="common-btn next-btn" [ngClass]="{ 'disable-pointer': verifyCardCodeLoader }"
									(click)="goToNextPage()">
									<ng-container *ngIf="!verifyCardCodeLoader; else showLoader;">
										{{ 'common.next' | translate }}
									</ng-container>
								</button>
							</div>
						</div>
					</mat-step>
					<mat-step [completed]="userDetailsForm.valid">
						<div class="stepper-contain">
							<h5 class="title-txt">{{ 'userDetail.enterDeliveryAddress' | translate }}</h5>
							<form [formGroup]="userDetailsForm">
								<div class="form-fields">
									<div class="field-row">
										<div class="field-box">
											<input placeholder="{{ 'userDetail.enterFirstName' | translate }}" type="text"
												formControlName="firstName"
												[ngClass]="{ 'field-error': formControls.firstName.touched && formControls.firstName.invalid }">
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: formControls.firstName }"></ng-container>
										</div>
										<div class="field-box">
											<input placeholder="{{ 'userDetail.enterLastName' | translate }}" type="text"
												formControlName="lastName"
												[ngClass]="{ 'field-error': formControls.lastName.touched && formControls.lastName.invalid }">
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: formControls.lastName }"></ng-container>
										</div>
									</div>
									<div class="field-row" formGroupName="address">
										<div class="field-box">
											<input placeholder="{{ 'userDetail.enterStreet' | translate }}" type="text" formControlName="street"
												[ngClass]="{ 'field-error': addressControls.street.touched && addressControls.street.invalid }">
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: addressControls.street }"></ng-container>
										</div>
										<div class="field-box">
											<input placeholder="{{ 'userDetail.enterZip' | translate }}" type="text" formControlName="zip"
												appAllowNumberOnly [regexType]="'digits'" maxlength="6"
												[ngClass]="{ 'field-error': addressControls.zip.touched && addressControls.zip.invalid }">
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: addressControls.zip }"></ng-container>
											<ng-container
												*ngTemplateOutlet="patternError; context: { control: addressControls.zip, message: ( 'validation.invalidZip' | translate ) }"></ng-container>
										</div>
										<div class="field-box">
											<input placeholder="{{ 'userDetail.enterCity' | translate }}" type="text" formControlName="city"
												[ngClass]="{ 'field-error': addressControls.city.touched && addressControls.city.invalid }">
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: addressControls.city }"></ng-container>
										</div>
										<div class="field-box">
											<ng-select class="custom-select" placeholder="{{ 'userDetail.selectCountry' | translate }}"
												[searchable]="false" [clearable]="false" formControlName="country"
												[ngClass]="{ 'field-error': addressControls.country.touched && addressControls.country.invalid }">
												<ng-option *ngFor="let country of countryList" [value]="country.value">
													{{ 'userDetail.' + country.label | translate }}
												</ng-option>
											</ng-select>
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: addressControls.country }"></ng-container>
										</div>
									</div>
									<div class="field-row">
										<div class="field-box">
											<input placeholder="{{ 'userDetail.enterEmail' | translate }}" type="text" formControlName="email"
												email [pattern]="emailRegex"
												[ngClass]="{ 'field-error': formControls.email.touched && formControls.email.invalid }">
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: formControls.email }"></ng-container>
											<ng-container
												*ngTemplateOutlet="patternError; context: { control: formControls.email, message: ( 'validation.invalidEmail' | translate ) }"></ng-container>
										</div>
										<div class="field-box">
											<app-cp-tel-input formControlName="phoneNo"
												[cssClass]="{ 'field-error': formControls.phoneNo.touched && formControls.phoneNo.invalid }"></app-cp-tel-input>
											<ng-container
												*ngTemplateOutlet="requiredError; context: { control: formControls.phoneNo }"></ng-container>
											<ng-container
												*ngTemplateOutlet="patternError; context: { control: formControls.phoneNo, message: ( 'validation.invalidPhoneNo' | translate ) }"></ng-container>
										</div>
									</div>
								</div>
	
								<div class="btn-box">
									<button class="common-btn back-btn" [ngClass]="{ 'disable-pointer': redeemCardCodeLoader }"
										matStepperPrevious>{{ 'common.back' | translate }}</button>
									<button class="common-btn next-btn" [ngClass]="{ 'disable-pointer': redeemCardCodeLoader }"
										(click)="onSubmit()">
										<ng-container *ngIf="!redeemCardCodeLoader; else showLoader;">
											{{ 'common.redeem' | translate }}
										</ng-container>
									</button>
								</div>
							</form>
						</div>
					</mat-step>
					<mat-step>
						<div class="stepper-contain">
							<div class="success-box">
								<div class="success-title">
									{{ 'landingPage.congratulations' | translate }}!
								</div>
								<div class="success-description mb-20">
									{{ 'landingPage.productRedeemedSuccessful' | translate }}
								</div>
								<div class="success-description">
									<span>
										{{ 'userDetail.toTrackYourOrder' | translate }}
									</span>
									<a [href]="orderDetail?.trackingUrl" class="order-link" target="_blank">
										{{ 'userDetail.clickHere' | translate }}
									</a>
								</div>
							</div>
						</div>
					</mat-step>
				</mat-stepper>
			</div>
		</div>
	</div>
</div>

<ng-template #requiredError let-control="control">
	<ng-container *ngIf="control.touched && control.errors?.required">
		<p class="error-message">{{ 'validation.fieldRequired' | translate }}</p>
	</ng-container>
</ng-template>
<ng-template #patternError let-control="control" let-message="message">
	<ng-container
		*ngIf="control.touched && (control.errors?.pattern || control.errors?.email || control.errors?.invalidNumber)">
		<p class="error-message">{{ message }}</p>
	</ng-container>
</ng-template>
<ng-template #showLoader>
	<app-cp-loader></app-cp-loader>
</ng-template>